/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

$font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

$primary-color: #A42145;
$second-color: #8B8B8B;
$third-color: #bb935b;
$fourth-color: #0C231E;
$fifth-color: #13322B;
$sixth-color: #652c90;
$seventh-color: #ef4026;
$eighth-color: #8cc63e;
$ninth-color: #00adef;
$tenth-color: #716558;
$ten-first-color: #f7931d;
$ten-second-color: #255b4e;

@font-face {
   font-family: "GMX";
   src: local("GMX"),url(./assets/fonts/GMX-Regular.ttf) format("truetype");
}

body {
   overflow-x: hidden;
}

.bg-primary {
   background-color: $primary-color !important;
}

.bg-sixth {
   background-color: $sixth-color !important;
}

.bg-seventh {
   background-color: $seventh-color !important;
}

.bg-eighth {
   background-color: $eighth-color !important;
}

.bg-ninth {
   background-color: $ninth-color !important;
}

.bg-tenth {
   background-color: $tenth-color !important;
}

.bg-ten-first {
   background-color: $ten-first-color !important;
}

.bg-ten-second {
   background-color: $ten-second-color !important;
}

.border-primary {
   border-color: $primary-color !important;
}

.border-third {
   border-color: $third-color !important;
}

@each $font-weight in $font-weights {
   .font-weight-#{$font-weight} {
      font-weight: $font-weight;
   }
}

hr {
   border: 0;
   border-top: 1px solid #dce0e0;
   margin: 0px !important;
   border-top-color: #dce0e0;
   opacity: 1 !important;
}

hr.with-decoration:before {
   content: "";
   width: 35px;
   height: 5px;
   background-color: $third-color;
   display: block;
   position: absolute;
}

.text-secondary {
   color: $second-color !important;
}

.text-third {
   color: $third-color !important;
}

.text-fourth {
   color: $primary-color !important;
}

.text-sixth {
   color: $sixth-color !important;
}

.text-seventh {
   color: $seventh-color !important;
}

.text-eighth {
   color: $eighth-color !important;
}

.text-ninth {
   color: $ninth-color !important;
}

.text-tenth {
   color: $tenth-color !important;
}

.background-green {
   background-color: $fourth-color !important;
}

.background-green-footer {
   background-color: $fifth-color !important;
}

.text-justify {
   text-align: justify;
}

ul.no-bullets {
   list-style-type: none;
}

ul {
   list-style: none; /* Remove default bullets */
 }
 
 ul li::before {
   content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
   color: $seventh-color; /* Change the color */
   font-weight: 900; /* If you want it to be bold */
   display: inline-block; /* Needed to add space between the bullet and the text */
   width: 1em; /* Also needed for space (tweak if needed) */
   margin-left: -1em; /* Also needed for space (tweak if needed) */
 }

 ul.no-bullets li::before {
   content: "";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
   color: $primary-color; /* Change the color */
   font-weight: 900; /* If you want it to be bold */
   display: inline-block; /* Needed to add space between the bullet and the text */
   width: 0; /* Also needed for space (tweak if needed) */
   margin-left: 0; /* Also needed for space (tweak if needed) */
 }

ul li ul li:before {
   content: '»'
}

 .accordion-button:not(.collapsed) {
   color: black !important;
   background-color: white !important;
   box-shadow: none !important;
}

.accordion-button::after {
   color: $primary-color !important;
}